<template>
  <!--begin::Container-->
  <div v-if="!$apollo.loading" class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            elevation="9"
            rounded
            color="grey"
            @click="$router.push('/market/order-detail/' + $route.params.id)"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div>Sold To: {{ seller_company_name }}</div>
            <div class="row ">
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Billing Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5> -->

                    <p class="mb-2">
                      {{ invoice.order.billToAddress.company_name }}<br />
                      {{ invoice.order.billToAddress.address_line1 }}<br />
                      {{ invoice.order.billToAddress.address_line2 }}<br />
                      {{ invoice.order.billToAddress.city }}
                      {{ invoice.order.billToAddress.state }}
                      {{ invoice.order.billToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ invoice.order.billToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->
              <div class="col-lg-4">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header">
                    <div class="card-title">
                      <h3 class="card-label">Shipping Info</h3>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5> -->

                    <p class="mb-2">
                      {{ invoice.order.shipToAddress.company_name }}<br />
                      {{ invoice.order.shipToAddress.address_line1 }}<br />
                      {{ invoice.order.shipToAddress.address_line2 }}<br />
                      {{ invoice.order.shipToAddress.city }}
                      {{ invoice.order.shipToAddress.state }}
                      {{ invoice.order.shipToAddress.zip_code }}
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-semibold mr-2">Phone:</span>
                      {{ invoice.order.shipToAddress.phone }}
                    </p>
                  </div>
                </v-card>
              </div>
              <!-- end col -->

              <div class="col-lg-4">
                <v-card flat>
                  <ul class="list-unstyled mb-0">
                    <li>
                      <p v-if="orders.length > 0" class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Purchase Order #:</span
                        >
                        {{ orders[0].po_number }}
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-semibold mr-2"
                          >Payment Type:</span
                        >
                        NET TERMS
                      </p>
                    </li>
                  </ul>
                </v-card>
              </div>
              <!-- end col -->
            </div>

            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">Create Invoice</h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Line ID</th>
                          <th style="width:180px;">SKU</th>
                          <th>Thumb</th>
                          <th style="width:180px;">Item</th>
                          <th class="text-left">Price</th>
                          <th style="width:150px;">Qty</th>
                          <th class="text-left">Qty to Return</th>
                          <th class="text-left">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in invoiceItems" :key="idx">
                          <td>{{ item.line }}</td>
                          <td>{{ item.sku }}</td>
                          <td>
                            <v-img
                              class="d-flex align-self-center mr-3 rounded-circle"
                              :src="
                                item.originalItem.main_image != null
                                  ? baseurl + item.originalItem.main_image.url
                                  : '/media/rollerskate.png'
                              "
                              alt="Generic placeholder image"
                              width="50"
                              height="50"
                            >
                            </v-img>
                          </td>
                          <td>
                            {{ item.productName }}
                            <span v-if="item.name != 'default'"
                              >({{ item.name }})</span
                            >
                          </td>
                          <td>{{ item.unitPrice | dollar_format }}</td>
                          <td>
                            Ordered {{ item.orderItem.quantity }} <br />
                            Invoiced {{ item.quantity }} <br />
                            Returned
                            {{
                              item.returnItems.reduce(
                                (a, c) => a + c.quantity,
                                0
                              )
                            }}
                          </td>
                          <td>
                            <v-text-field
                              v-model.number="item.quantityToReturn"
                              type="number"
                              label="qty to return"
                              dense
                              outlined
                              number
                              style="width: 100px"
                              min="0"
                              :max="item.quantity"
                            ></v-text-field>
                          </td>
                          <td>
                            {{
                              (item.unitPrice * item.quantityToReturn)
                                | dollar_format
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Subtotal
                          </td>
                          <td>{{ subtotal | dollar_format }}</td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Shipping refund
                          </td>
                          <td>
                            <v-text-field
                              v-model.number="shippingRefund"
                              prepend-inner-icon="mdi-currency-usd"
                              type="number"
                              number
                              label="Price"
                              min="0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">
                            Restock fee
                          </td>
                          <td>
                            <v-text-field
                              v-model.number="restockFee"
                              prepend-inner-icon="mdi-currency-usd"
                              number
                              type="number"
                              label="Price"
                              min="0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="7" style="text-align: right;">Total</td>
                          <td>
                            {{ total | dollar_format }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <!--end: Datatable-->
                    <div class="text-lg-right">
                      <v-btn @click="refundOrder">
                        Refund Order Offline - No Payment
                      </v-btn>
                      <v-btn @click="refundOrder">
                        Refund Order
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import GetInvoiceDetails from "@/graphql/queries/GetInvoiceDetails.gql";
import CreateFullReturn from "@/graphql/mutations/CreateFullReturn.gql";
import { pick } from "lodash";
export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    restockFee: 0,
    shippingRefund: 0,
    seller_company_name: "",
    shipping_fixed_rate: 0,
    shipping_fee_type: "item",
    shipping_amount: 0,
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
    invoiceItems: [],
  }),
  apollo: {
    invoice: {
      query: GetInvoiceDetails,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  computed: {
    subtotal() {
      if (!this.invoice) {
        return 0;
      }
      return this.invoiceItems
        .map((i) => i.unitPrice * i.quantityToReturn)
        .reduce((a, c) => a + c, 0);
    },
    total() {
      return this.subtotal + this.shippingRefund - this.restockFee;
    },
  },
  watch: {
    invoice(newValue) {
      if (!newValue) {
        return;
      }
      this.invoiceItems = newValue.invoiceItems.map((i) => ({
        ...i,
        quantityToReturn: 0,
      }));
    },
  },
  methods: {
    async refundOrder() {
      const returnItems = this.invoiceItems.map((i) => ({
        ...pick(i, [
          "unitPrice",
          "name",
          "sku",
          "productName",
          "lineTotal",
          "line",
        ]),
        quantity: i.quantityToReturn,
        invoiceItem: i.id,
        orderItem: i.orderItem.id,
      }));
      const returnInput = {
        shippingRefund: this.shippingRefund,
        restockFee: this.restockFee,
        subtotal: this.subtotal,
        total: this.total,
        invoice: this.invoice.id,
        order: this.invoice.order.id,
      };

      await this.$apollo.mutate({
        mutation: CreateFullReturn,
        variables: {
          returnInput,
          returnItems,
        },
      });
      await this.$apollo.queries.invoice.refetch();
      await Swal.fire({
        title: "",
        text: "Updated successfully.",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
      this.$router.push(
        "/market/order-detail/" + this.invoice.order.identifier
      );
    },
  },
};
</script>
